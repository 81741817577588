import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { DialogHeader, DialogFooter } from './styles';

export default function ModalMessageDetailDialog({
  annotation,
  open,
  handleClose,
}) {
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={true}>
      <DialogTitle>
        <DialogHeader>
          <span>Detalhes da Mensagem</span>
        </DialogHeader>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{annotation}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogFooter>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogFooter>
      </DialogActions>
    </Dialog>
  );
}
