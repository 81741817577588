import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Tooltip, Grid, Box } from '@material-ui/core';
import { useChatContext } from 'pages/chats/ChatsContext';
// import ButtonBase from '@material-ui/core/ButtonBase';
import lazyRetry from 'helpers/lazyRetry';
import ProposalServices from 'services/proposalServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ModalMessageDetailDialog from './components/ModalMessageDetailDialog';
import AutoComplete from 'components/atoms/AutoComplete';
import Wrapper from 'components/Wrapper';
import {
  Container,
  Separator,
  WrapperContainer,
  Text,
  Reminder,
  LabelLight,
  OriginTag,
  LabelMessage,
  ContainerMessage,
  ButtonLink,
} from './styles-sheduleds';

const RemoveScheduled = React.lazy(() =>
  lazyRetry(() => import('components/RemoveScheduled'))
);

const OPTIONS_CLIENT_APPEARED = [
  {
    label: 'Sim',
    value: 1,
    done: true,
  },
  {
    label: 'Não',
    value: 2,
    done: false,
  },
  {
    label: 'Sem Resposta',
    value: 3,
    done: null,
  },
];

const Scheduleds = props => {
  const dispatch = useDispatch();
  const { currentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalRemoveScheduleds: false,
    openModalMessageDetailDialog: false,
    scheduleDoneSelected: {
      label: 'Sem Resposta',
      value: 3,
      done: null,
    },
  });

  const handleClickCloseModalRemoveScheduleds = () => {
    setState(prevState => ({ ...prevState, openModalRemoveScheduleds: false }));
  };

  const { item, seller } = props;

  const messageSplit = item?.message?.split('Proposta:')?.[0] || item?.message;

  const putDoneSchedule = async (scheduleId, isDone) => {
    try {
      const { success } = await ProposalServices.changeDoneSchedule(
        currentProposal.dealerId,
        currentProposal.id,
        scheduleId,
        isDone
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          scheduleDone: !state.scheduleDone,
        }));
        props.callbackOnSuccess();
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const handleSelectedScheduled = (scheduledResponse, changeSelected) => {
    if (changeSelected) {
      putDoneSchedule(props.item?.id, scheduledResponse?.done);
    }
    if (!scheduledResponse) {
      setState(prevState => ({
        ...prevState,
        scheduleDoneSelected: {
          label: 'Sem Resposta',
          value: 3,
          done: null,
        },
      }));
      return;
    }
    setState(prevState => ({
      ...prevState,
      scheduleDoneSelected: scheduledResponse,
    }));

    return;
  };

  useEffect(() => {
    handleSelectedScheduled(
      OPTIONS_CLIENT_APPEARED.find(el => el.done === props.item?.done),
      false
    );
  }, [props.item?.done]);

  return (
    <>
      <Grid xs={12} direction="column">
        <Container>
          <Separator />
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexGrow="1"
            padding="12px"
          >
            <WrapperContainer>
              <Wrapper
                flex
                width={'100%'}
                direction="row"
                justify="between"
                mb={8}
              >
                <Wrapper flex width={'100%'}>
                  <Text>
                    {item?.type?.name && (
                      <OriginTag>{item?.type?.name}</OriginTag>
                    )}
                  </Text>
                </Wrapper>

                {item.scheduledDate && (
                  <Reminder>
                    <LabelLight>
                      {moment(item.scheduledDate).format('DD/MM/YYYY HH:mm')}
                    </LabelLight>
                  </Reminder>
                )}
              </Wrapper>
              {item?.message && messageSplit.length > 2 && (
                <ContainerMessage>
                  <Tooltip title={'Mensagem: ' + messageSplit}>
                    <LabelMessage>{messageSplit}</LabelMessage>
                  </Tooltip>
                  {messageSplit.length > 59 && (
                    <ButtonLink
                      onClick={() =>
                        setState(prevState => ({
                          ...prevState,
                          openModalMessageDetailDialog: true,
                        }))
                      }
                    >
                      Ler tudo
                    </ButtonLink>
                  )}
                </ContainerMessage>
              )}

              <Wrapper width={'100%'} mb={8} mt={8}>
                <AutoComplete
                  options={OPTIONS_CLIENT_APPEARED}
                  label="Cliente compareceu?"
                  value={
                    state.scheduleDoneSelected?.value
                      ? {
                          value: state.scheduleDoneSelected?.value,
                          label: OPTIONS_CLIENT_APPEARED.find(
                            el => el.value === state.scheduleDoneSelected?.value
                          )?.label,
                        }
                      : null
                  }
                  onChange={event => handleSelectedScheduled(event, true)}
                  disabledOptions={[3]}
                />
              </Wrapper>

              {!!seller && (
                <Tooltip title={'Autor da anotação: ' + seller}>
                  <Box
                    component="div"
                    display="flex"
                    width="max-content"
                    align-items="center"
                    fontWeight="bold"
                    font-size="12px"
                    padding="0 12px"
                    marginRight="5px"
                    borderRadius="90px"
                    color="#333333"
                    style={{ backgroundColor: '#eeeeee' }}
                  >
                    {seller}
                  </Box>
                </Tooltip>
              )}
            </WrapperContainer>
          </Box>
        </Container>
      </Grid>

      {state.openModalRemoveScheduleds && (
        <RemoveScheduled
          open={state.openModalRemoveScheduleds}
          item={item}
          itemSelected={state.itemSelectedRemoveScheduleds}
          {...props}
          handleClose={handleClickCloseModalRemoveScheduleds}
        />
      )}

      <ModalMessageDetailDialog
        annotation={messageSplit}
        open={state.openModalMessageDetailDialog}
        handleClose={() =>
          setState(prevState => ({
            ...prevState,
            openModalMessageDetailDialog: false,
          }))
        }
      />
    </>
  );
};

export default Scheduleds;
