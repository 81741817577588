import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import lazyRetry from 'helpers/lazyRetry';
import { useChatContext } from 'pages/chats/ChatsContext';
import Scheduleds from './Scheduleds';
import ItemHeader from '../atoms/ItemHeader';
import { Button } from './styles';

const AddSchedule = React.lazy(() =>
  lazyRetry(() => import('components/AddSchedule'))
);

const Scheduling = ({ closedProposal = () => null }) => {
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalAddScheduled: false,
  });

  const handleClickOpenModalAddScheduling = () => {
    setState({ openModalAddScheduled: !state.openModalAddScheduled });
  };

  const handleClickCloseModalAddScheduling = () => {
    setState({ openModalAddScheduled: false });
  };

  return (
    <>
      <ItemHeader>Agendamento</ItemHeader>
      <div
        style={{
          padding: 16,
          height: `calc(100vh - 280px)`,
          overflowY: 'auto',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          {currentProposal.scheduleds &&
            currentProposal.scheduleds.map((item, id) => {
              return (
                <Scheduleds
                  key={id}
                  id={id}
                  item={item}
                  seller={currentProposal?.sellerName}
                  callbackOnSuccess={() => {
                    getCurrentProposal();
                  }}
                />
              );
            })}
        </Grid>
        {!closedProposal && (
          <Button onClick={handleClickOpenModalAddScheduling}>
            Agendar contato
          </Button>
        )}
      </div>
      {state.openModalAddScheduled && (
        <AddSchedule
          open={state.openModalAddScheduled}
          handleClose={handleClickCloseModalAddScheduling}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            getCurrentProposal();
            handleClickCloseModalAddScheduling();
          }}
        />
      )}
    </>
  );
};

export default Scheduling;
